.container {
  max-width: 90% !important;
  width: 100%;
  position: absolute;
  background-color: #FFF;
  border: 0px solid #000;
  border-radius: 11px;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
  padding: 20px 30px;
  overflow: hidden;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .titleContainer {
      padding: 10px 0;
    }
  }

  .closeSymbolContainer {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;

    img {
      width: 25px;
      filter: opacity(0.7);
    }
  }

  .modalContent {
    overflow: auto;
    padding: 5px 0;
    max-height: calc(80vh - 50px);
  }

  .actionsContainer {
    text-align: right;
    margin-top: 10px;
  }
}