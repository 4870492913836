.noExpanIcon .MuiTable-root thead tr th:first-child,
.noExpanIcon .MuiTable-root tbody tr td:first-child:not([colspan]) {
  display: none;
}

.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
  box-shadow: none;
  border: none;
}

.MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
  padding: 0;
}

.MuiSvgIcon-root.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionAsc {
  opacity: 0.2;
}

.MuiButtonBase-root.MuiTableSortLabel-root,
.MuiButtonBase-root.MuiTableSortLabel-root.MuiTableSortLabel-active,
.MuiButtonBase-root.MuiTableSortLabel-root:hover,
.MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiSvgIcon-root.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionAsc,
.MuiButtonBase-root.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiSvgIcon-root.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionAsc,
.MuiButtonBase-root.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiSvgIcon-root.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionDesc {
  color: #fff;
}


.MuiTableCell-root.MuiTableCell-footer.MuiTablePagination-root.Component-paginationRoot-1 {
  border-bottom: 0;
}

.dataTableContainer td .MuiSvgIcon-root {
  color: #595959;
}

.MuiSvgIcon-root.MuiSvgIcon-colorPrimary {
  color: green !important;
}

.MuiSvgIcon-root.MuiSvgIcon-colorSecondary {
  color: #f44336 !important;
}

.MuiSvgIcon-root.MuiSvgIcon-colorError {
  color: #000 !important;
}

.selectTableAdmin {
  white-space: normal !important;
  max-width: 300px;
}



