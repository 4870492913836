.footer {
  display: flex;
  width: 100%;
  height: 50px;
  
  nav {
    width: 100%;
    margin: 0 auto;
    border-top: 2px solid #B9B5B5;
    
    menu {
      max-width: 300px;
      margin: 0 auto;
      display: flex;
      height: 100%;
      justify-content: space-around;
      align-items: center;
    }
  }
}