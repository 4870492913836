.CommonInputContainer .MuiOutlinedInput-root fieldset,
.CommonInputContainer .MuiOutlinedInput-root.Mui-focused fieldset,
.CommonInputContainer .MuiOutlinedInput-root:hover fieldset,
.CommonInputContainer .MuiOutlinedInput-root.Mui-focused:hover fieldset {
  border-color: black;
  border-width: 2px;
  
}

.CommonInputContainer .MuiOutlinedInput-root input,
.MuiPaper-root .MuiList-root  .MuiListItem-root,
.CommonInputContainer .MuiOutlinedInput-root .MuiSelect-root  {
  color: #595962;
}

.CommonInputContainer .MuiSelect-icon {
  color: black;
}

.CommonInputContainer .MuiFormLabel-root {
  opacity: 0.6;
}

.CommonInputContainer .MuiFormControl-root .MuiFormLabel-root.MuiFormLabel-filled,
.CommonInputContainer .MuiFormControl-root .MuiFormLabel-root.Mui-focused,
.CommonInputContainer .MuiFormLabel-root.MuiInputLabel-shrink,
.CommonInputContainer .MuiFormLabel-root.Mui-focused.MuiInputLabel-shrink {
  color: black;
  opacity: 1;
}


