.commonLinkContainer {
  display: inline;
}

.commonLinkContainer .MuiLink-root {
  color: #595962;
}

.commonLinkContainer .MuiLink-root.MuiTypography-colorPrimary {
  color: #595962 !important;
}
.commonLinkContainer .MuiLink-root.MuiTypography-colorSecondary {
  color: #0398FF !important;
}
.commonLinkContainer .MuiLink-root.terciary.MuiTypography-colorSecondary {
  color: #FFFFFF !important;
}
.commonLinkContainer .MuiLink-root.MuiTypography-colorTextPrimary {
  color: green !important;
}

.commonLinkContainer .MuiLink-root.MuiTypography-colorTextSecondary {
  color: #FA9658 !important;
}