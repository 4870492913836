.commonTextContainer .MuiTypography-root,
.MuiTypography-root.MuiTypography-body1 {
  color: #595962 !important;
}

.commonTextContainer .MuiTypography-root.MuiTypography-colorPrimary {
  color: #595962 !important;
}
.commonTextContainer .MuiTypography-root.MuiTypography-colorSecondary {
  color: #33324A !important;
}
.commonTextContainer .terciary.MuiTypography-root.MuiTypography-colorSecondary {
  color: #FFFFFF !important;
}
.commonTextContainer .MuiTypography-root.MuiTypography-colorTextPrimary {
  color: green !important;
}

.commonTextContainer .MuiTypography-root.MuiTypography-colorTextSecondary {
  color: #FA9658 !important;
}

.commonTextContainer .MuiTypography-root.MuiTypography-colorError {
  color: #f44336 !important;
}


