.topContainer {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 50px);

  .adminHeader {
    padding: 0 20px;
    width: 100%;
  }
  .mainContentContainer {
    height: 100%;
    min-height: calc(100vh - 90px);
    display: flex;

    .mainMenu {
      transition: width 0.3s, left 0.3s;
      width: 200px;
      background: #f0f0f0;
      border-right: 1px solid #ebebeb;
      position: relative;
      align-items: center;
      color: #666;
      padding-top: 15px;

      .mainMenuOptions {
        max-height: calc(100% - 60px);
        overflow: auto;
      }

      .menuItemContainer {
        a {
          transition: width 0.3s, left 0.3s;
          width: 199px;
          display: flex;
          align-items: center;
          padding: 12px 14px;
        }

        svg {
          margin-right: 20px;
          filter: invert(45%) sepia(33%) saturate(6254%) hue-rotate(185deg)
            brightness(104%) contrast(106%);
        }
        &:hover {
          background-color: rgb(0 0 0 / 0.04);
        }
      }

      .contentCollapse {
        border-top: 1px solid #dbdbdb;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 12px 14px;
        width: 199px;
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
          transform: rotate(180deg);
          margin-right: 20px;
        }
        &:hover {
          background-color: rgb(0 0 0 / 0.04);
        }
      }

      &.collapsed {
        width: 55px;
        min-width: 55px;
        overflow: hidden;

        .contentCollapse {
          svg {
            transform: rotate(0deg);
          }
        }
      }
    }
    .contentBody {
      width: 100%;
      max-height: calc(100vh - 90px);
      padding: 20px;
      overflow-y: auto;
    }
  }
}
