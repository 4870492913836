.CommonButtonContainer .MuiButton-root {
  padding: 8px 40px;
  color: #FFFFFF;
  text-transform: none;
  background-color: #0398FF;
}

.CommonButtonContainer .MuiButton-root:hover {
  background-color: #33a8fb;
}

.CommonButtonContainer .MuiButton-containedPrimary.MuiButton-root {
  color: #FFFFFF;
  text-transform: none;
  background-color: #0398FF;
}

.CommonButtonContainer .MuiButton-containedPrimary.MuiButton-root:hover {
  background-color: #33a8fb;
}

.CommonButtonContainer .MuiButton-containedSecondary.MuiButton-root {
  color: #0398FF;
  text-transform: none;
  background-color: #FFF;
}

.CommonButtonContainer .MuiButton-containedSecondary.MuiButton-root:hover {
  background-color: #f5f5f5;
}

.CommonButtonContainer .terciary.MuiButton-containedSecondary.MuiButton-root {
  color: #595962
}

.CommonButtonContainer .MuiButton-root.MuiButton-sizeSmall {
  padding: 4px 8px;
}
.CommonButtonContainer .MuiButton-root.MuiButton-sizeSmall span {
  font-size: 0.9rem;
}

.CommonButtonContainer .MuiButton-root.MuiButton-sizeLarge {
  padding: 10px 55px;
}
