:root {
	--text-color: #595962;
}

html, body, div, span, h1, h2, h3, h4, h5, h6, p, a, img, sup, u, i, ol, ul, li, form, label,
table, tbody, tfoot, thead, tr, th, td, aside, footer, header, menu, nav, section, audio {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	box-sizing: border-box;
	text-decoration: none;
	outline: none;
}

article, aside, footer, header, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
	background-color: #fff !important;
}
ol, ul {
	list-style: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

form {
	width: 100%;
}

h1, h2, h3, h4, h5, h6, a, label, input, select, ul, li, div, p {
	// color: var(--text-color) !important;
}

a {
	cursor: pointer;
}

*, body {
	font-family: Lato !important;
	font-size: 14px;
}

html {
	font-size: 14px;
}
